.city-wise-pricing {
    max-width: 90%;
    margin: 0 auto;
    /* margin-bottom: 100px; */
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
  }
  
  .city-wise-title {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .city-wise-description,
  .city-wise-process,
  .city-wise-specific {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  .login-form {
    width: 40%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
 .get-in-touch-container1{
  display: flex;
 }
 .flex-touch-first{
  flex:6;
 }
.form-touch-second{
  flex: 6;
  width: 50%;
}
  .custom-form-group {
    margin-bottom: 15px;
    margin-right: 10px;
  }
  .compact-form-input {
    appearance: none;
    border: 1px solid #ccc;
    padding: 0.5rem;
    font-size: 16px;
    width: 90%;
  }
  
  
  .custom-form-group input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .but2 {
    width: 100%;
    padding: 10px;
    background-color: #ac35b3;
    color: #fff;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .but2:hover {
    background-color: #ac35b3;
  }
  .banner-catchy-linex {
    padding: 7px;
    background-color: #fff;
    text-align: center;
    font-size: 18px;
  }
  .form-touch{
    width: 50%;
  }
  @media(max-width:676px){
    .login-form{
      width: 90%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
    .custom-form-group input {
      width: 200px;
      padding: 8px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }

  /* Popular Searches Section Styling */
.popular-searches {
  padding: 30px;

  border-radius: 10px;
  color: #333;
  font-family: 'Merriweather', serif; /* Elegant serif font */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 100%;
}

.popular-searches h4 {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3); /* Text shadow for clarity */
}

.search-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive columns */
  gap: 15px;
}

.search-item {
  font-size: 16px;
  color: #333; /* Distinctive link color */
  background-color: #ffffff; /* Clean background */
  border-left: 5px solid #ff758c;

  text-align: center;
  padding: 10px 15px;
  border-radius: 8px; /* Rounded corners */
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow */
}

.search-item:hover {

  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
  transform: translateY(-2px); /* Slight lift */
}

@media (max-width: 768px) {
  .popular-searches {
    padding: 20px;
  }

  .search-item {
    font-size: 14px;
    padding: 8px 12px;
  }
}

  
  .photography-available {
    padding: 30px;

  border-radius: 10px;
  color: #333;
  font-family: 'Merriweather', serif; /* Elegant serif font */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 100%;
  }
  .photography-available h4 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3); /* Text shadow for clarity */
  }
  
  .city-columns {
    display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive columns */
  gap: 15px;
  }
  
  .city-item {
    font-size: 16px;
  color: #333; /* Distinctive link color */
  background-color: #ffffff; /* Clean background */
  border-left: 5px solid #ff758c;

  text-align: center;
  padding: 10px 15px;
  border-radius: 8px; /* Rounded corners */
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow */
  }
  
  .city-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
  transform: translateY(-2px); /* Slight lift */
  }
  
  .other-photography-services {
    padding: 20px;
    background-color: #fff;
  }
  .other-photography-services {
    padding: 30px;

  border-radius: 10px;
  color: #333;
  font-family: 'Merriweather', serif; /* Elegant serif font */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 100%;
  }
  .other-photography-services h4 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3); /* Text shadow for clarity */
  }
  
  
  
  .service-columns {
    display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive columns */
  gap: 15px;
  }
  
  .service-itemx {
    font-size: 16px;
  color: #333; /* Distinctive link color */
  background-color: #ffffff; /* Clean background */
  border-left: 5px solid #ff758c;

  text-align: center;
  padding: 10px 15px;
  border-radius: 8px; /* Rounded corners */
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow */
  }
  
  .service-itemx:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
  transform: translateY(-2px); /* Slight lift */
  }
@media (max-width:787px){
  .photography-available {
    padding: 20px;

  border-radius: 10px;
  color: #333;
  font-family: 'Merriweather', serif; /* Elegant serif font */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 100%;
  }
  .other-photography-services {
    padding: 20px;

  border-radius: 10px;
  color: #333;
  font-family: 'Merriweather', serif; /* Elegant serif font */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 100%;
  }
  .city-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  .city-item {
    padding: 8px 12px;
    font-size: 12px;
  }
  .search-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  .service-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  
  .service-itemx {
    padding: 8px 12px;
    font-size: 12px;
  }
  
  .service-itemx:hover {
    background-color: #f0f0f0;
  }
}