.responsive-image-gallery {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.image-container {
  position: relative;
  width: 100%;
}

.gallery-image {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.gallery-image:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .responsive-image-gallery {
    padding: 10px;
  }

  .gallery-image {
    border-radius: 0;
  }
}
.gallery-image:last-child {
  position: relative;
  overflow: hidden;
}

.gallery-image:last-child::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid; 
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
}

.gallery-image:last-child:hover::before {
  opacity: 1;
  border-color: #a1b; 
}
