/* Add this CSS in your stylesheet or CSS file */

.slide-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.overlay {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  padding: 20px;
  text-align: center;
  filter: blur(0);
  transition: filter 0.3s ease-in-out;
  z-index: 2;
}

.slide-content:hover .overlay {
  /* display: none; */
  filter: blur(2px);
}

.slide-content p {
  margin: 0;
  font-weight: bold;
  font-family:"Montserrat Alternates"
  /* Additional styling as needed */
}
.slide-content img {
  width: 450px;
  height: 450px; /* Adjust height as needed */
  object-fit: cover; /* Or object-fit: cover; depending on your preference */
  z-index: 1;
}

/* For mobile devices */
@media screen and (max-width: 768px) {
  .slide-content img {
    height: auto; /* Allow the height to adjust according to the width */
    max-width: 100%;
    object-fit: fill;
  }
  .overlay {
    position: absolute;
    width: 80px;
    height: 20px;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.7);
    padding: 2px;
    text-align: center;
    filter: blur(0);
    transition: filter 0.3s ease-in-out;
    z-index: 2;
  }
  .overlay-text{
    font-size: 12px;
  }
}
.custom-next-button,
.custom-prev-button {
  color: #ff0000; /* Replace with your desired color */
}

.custom-next-button:hover,
.custom-prev-button:hover {
  color: #00ff00; /* Replace with your desired color for the hover state */
}
.swiper-button-prev {
  color: #bac383; /* Change the color of the previous button */
}

.swiper-button-next {
  color: #bac383; /* Change the color of the next button */
}

/* Optionally, you can customize the size and other properties of the navigation buttons */
.swiper-button-prev,
.swiper-button-next {
  width: 28px; /* Adjust width */
  height: 28px; /* Adjust height */
  background-color: transparent; /* Set background color */
}
.swiper-pagination-bullet{
  background-color: #bac383;
}