.dash{
    width: 100%;
    margin-top: 20px;
}
.Invoice-box{
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 100px;
    width: 750px;
    height: 1000px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 40px;
    position: relative;
}
.heading-invoice{
    color: dimgrey;
    font-weight: bold;
    font-size: 36px;
}
.heading-fotoshoot{
    color: black;
    font-weight: bold;
    font-size: 25px;
}
.invoice-details{
    font-size: 12px;
}
.invoice-ship-details{
    font-size: 15px;
    display: grid;
    grid-template-columns: 0.8fr 0.8fr 1.4fr;
    gap: 20%;
}
.invoice-number{
    font-size: 12px;
}
/* table */
.table-div{
  height:45%;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #ccc;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #ddd;
  }
  .digitalSig{
    height:100px;
    width:100px;
    margin-left:80%;
  }

  .editinvoice{
    position: absolute;
    bottom: 10;
    background-color: #ac35b3;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .editinvoice:hover{
    background-color: #ac35b3;
  }
  .submit{
    position: absolute;
    bottom: 10;
    right: 40px;
    background-color: #ac35b3;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    }
    .submit:hover{
      background-color: #ac35b3;
    }
@media only screen and (max-width:650px) {
    .Invoice-box{
        margin: 0 auto;
        width: 100%;
        border: 1px solid black;
        border-radius: 5px;
        padding: 10px;
    }
    .heading-invoice{
        color: dimgrey;
        font-weight: bold;
        font-size: 30px;
    }
    .heading-fotoshoot{
        color: black;
        font-weight: bold;
        font-size: 20px;
    }
    .invoice-details{
        font-size: 8px;
    }
    .invoice-ship-details{
        font-size: 10px;
        display: grid;
        grid-template-columns: 0.7fr 0.7fr 1.6fr;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      border: 1px solid #ccc;
    }
    
    th, td {
      border: 1px solid #ccc;
      padding: 4px;
      text-align: left;
    }
    
    th {
      background-color: #f2f2f2;
      font-weight:smaller;
    }
    
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    
    tr:hover {
      background-color: #ddd;
    }
    .digitalSig{
      height:100px;
      width:100px;
      margin-left:70%;
    }
}
@media only screen and (max-width:395px) {
  .Invoice-box{
      margin: 0 auto;
      width: 100%;
      border: 1px solid black;
      border-radius: 5px;
      /* padding: 10px; */
  }
  .heading-invoice{
      color: dimgrey;
      font-weight: bold;
      font-size: 27px;
  }
  .heading-fotoshoot{
      color: black;
      font-weight: bold;
      font-size: 19px;
  }
  .invoice-details{
      font-size: 8px;
  }
  .invoice-ship-details{
      font-size: 10px;
      display: grid;
      grid-template-columns: 0.7fr 0.7fr 1.6fr;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-sizing: border-box;
    /* border: 1px solid #ccc; */
  }
  
  th, td {
    border: 1px solid #ccc;
    /* padding: 5px; */
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
    font-weight:smaller;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #ddd;
  }
  .digitalSig{
    height:100px;
    width:100px;
    margin-left:70%;
  }
}
@media only screen and (max-width:370px) {
  .Invoice-box{
      margin: 0 auto;
      width: 100%;
      border: 1px solid black;
      border-radius: 5px;
      /* padding: 10px; */
  }
  .heading-invoice{
      color: dimgrey;
      font-weight: bold;
      font-size: 25px;
  }
  .heading-fotoshoot{
      color: black;
      font-weight: bold;
      font-size: 18px;
  }
  .invoice-details{
      font-size: 7px;
  }
  .invoice-ship-details{
      font-size: 9px;
      display: grid;
      grid-template-columns: 0.7fr 0.7fr 1.6fr;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-sizing: border-box;
    /* border: 1px solid #ccc; */
  }
  
  th, td {
    border: 1px solid #ccc;
    /* padding: 5px; */
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
    font-size: xx-small;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #ddd;
  }
  .digitalSig{
    height:100px;
    width:100px;
    margin-left:70%;
  }
}
.loading-animation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}