.about-container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .section-heading {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .sub-heading {
    font-size: 20px;
    font-weight: bold;
    color: #555;
  }
  
  .paragraph {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
  }
  
  /* Add more styles as needed */
  