/* General styling for the offer card */
.offer-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 9999;
  font-family: 'Arial', sans-serif;

  /* Highlighting border */
  border: 4px solid transparent;
  background-image: linear-gradient(white, white), linear-gradient(90deg, #ff7eb3, #ff758c, #ff7eb3);
  background-origin: border-box;
  background-clip: content-box, border-box;
}


/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* Layout for the content inside the offer card */
.offer-content {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

/* Styling for the image section */
.image-section {
  flex: 1;
  text-align: center;
  margin-right: 20px;
}

.offer-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* Styling for the form section */
.form-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-section h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.form-section p {
  font-size: 14px;
  margin-bottom: 20px;
}

.offer-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 14px;
  margin-bottom: 5px;
}

.form-group input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Styling for the submit button */
.submit-btn {
  padding: 10px;
  font-size: 16px;
  background-color: #ff758c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #e65a5a;
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 600px) {
  .offer-card {
    width: 95%;
  }

  .offer-content {
    flex-direction: column;
  }

  .image-section {
    margin-right: 0;
    margin-bottom: 20px;
  }
}