/* ServicesPage.css */
.services-page {
    display: flex;
    /* background-color: #f9f9f9; */
    padding: 20px;
    
  }
  
  .main-content {
    flex: 1;
    padding-left: 20px;
    z-index: 1;
    position: relative;
  }
  .side-menu {
    flex: 0 0 250px;
    /* Rest of your styles */
  }
  
  
  /* Media query to adjust layout for smaller screens */
  @media (max-width: 768px) {
    .services-page {
      flex-direction: column;
      /* margin-top: 60px; */
    }
  }
  