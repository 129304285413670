/* SignupPage.css */

.signup-container {
    text-align: center;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 100px;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .signup-heading {
    margin-bottom: 20px;
  }
  
  .signup-input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .signup-btn {
    background-color: #a1b;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .signup-btn:hover {
    background-color: #a1b;
  }
  