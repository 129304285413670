.sitemap-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .sitemap-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    width: 100%;
    max-width: 100%; /* Adjust the max-width as needed */
  }
  
  .sitemap-list li {
    flex: 1 1 calc(25% - 20px); /* Adjust the base width to fit 4 links in a row */
    margin: 10px;
  }
  
  .sitemap-list a {
    display: block;
    text-decoration: none;
    color: #0073e6;
    transition: color 0.3s;
    word-wrap: break-word;
    font-size: 14px; /* Adjust font size as needed */
  }
  
  .sitemap-list a:hover {
    color: #005bb5;
  }
  