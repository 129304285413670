/* TestimonialPage.css */
.testimonial-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 50px; */
  /* background-color: #f7f7f7; */
}

.recent-reviews {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.recent-review {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 45%;
  box-shadow: 2px 6px 8px rgba(88, 72, 72, 0.5);
  backdrop-filter: blur(8px); /* Apply blur effect */
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.quote-icon {
  color: #a1b;
  font-size: 24px;
  margin-right: 10px;
}

.rating {
  color: #a1b;
  font-size: 18px;
}

.star-icon {
  margin-left: 5px;
}

.review-text {
  color: #555;
  font-size: 16px;
  margin-bottom: 15px;
}

.review-author {
  color: #777;
  font-size: 14px;
}

.feedback-form {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center; 
  box-shadow: 2px 6px 8px rgba(88, 72, 72, 0.5);
}

.feedback-form h2 {
  color: #a1b;
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
}

.feedback-form input,
.feedback-form select,
.feedback-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.feedback-form textarea {
  resize: vertical;
}

.feedback-form button {
  background-color: #a1b;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.photo-icon {
  color: #a1b;
  font-size: 40px;
  margin-top: 30px;
}
@media  (max-width: 768px) {
  .recent-review {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 95%;
    box-shadow: 2px 6px 8px rgba(88, 72, 72, 0.5);
    backdrop-filter: blur(8px); /* Apply blur effect */
  }
}
  