.card {
  height: 400px;
  margin-top: 10px;
}

.card-body {
  text-align: center;
}
.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.card-img{
  border-radius: 5px;
  height: 200px;
}

.card-subtitle {
  font-size: 1.2rem;
}


.btn1 {
  background-color: #ac35b3;
  border-color: #ac35b3;
  color: white;
  font-size: 1rem;
  border-radius: 20px;
  border: 0;
  padding: 10px 20px;
  font: weight 4px;
  margin-top: 10px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3; 
}

@media (max-width: 767px) {
  .mb-4 {
    margin-bottom: 15px;
  }
}
