.main-invoice-div{
    width: 80vw;
    min-height: 100vh;
}
.payment-button{
  display: flex;
    color: white;
    background-color: #ac35b3;
    border-radius: 5px;
    border:0;
    justify-content:center ;
    align-items: center;
    padding: 5px;
    margin: auto;
}
.payment-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 16px;
  }
  
  .payment-table th,
  .payment-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  /* Styles for larger screens */
  @media (min-width: 768px) {
    .payment-table th,
    .payment-table td {
      padding: 15px;
    }
  }
  

  @media (max-width: 767px) {
    .main-invoice-div{
        width: 100vw;
        height:100%;
    }
    .payment-table {
      font-size: 14px;
    }
  
    .payment-table th,
    .payment-table td {
      padding: 8px;
      display: block;
    }
  
    .payment-table thead {
      display: none;
    }
  
    .payment-table tbody tr {
      margin-bottom: 15px;
      display: block;
      border: 1px solid #ddd;
    }
  
    .payment-table tbody td::before {
      content: attr(data-label);
      font-weight: bold;
      display: inline-block;
      width: 100px;
    }
  
    .payment-button {
      display: block;
      width: 100%;
      margin-top: 10px;
    }
  }
  