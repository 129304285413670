.cities-container {
  max-width: 100%;
  margin: 30px auto; /* Centers the container */
  padding: 25px;
  background: linear-gradient(135deg, #ac35b3, #ff758c); /* Gradient background for vibrance */
  border-radius: 12px; /* Smooth corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  position: relative;
  color: #fff; /* Ensure text is visible on background */
  text-align: center;
}

.cities-container h2 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
  font-family: 'Playfair Display', serif; /* Elegant font for the heading */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Subtle text shadow for emphasis */
}

.city-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px; /* Adds spacing between elements */
}

.city-input {
  padding: 12px;
  width: 60%; /* Responsive width */
  font-size: 16px;
  border: 2px solid #fff; /* Prominent border for focus */
  border-radius: 25px; /* Fully rounded edges */
  background-color: rgba(255, 255, 255, 0.2); /* Transparent background */
  color: #fff; /* Text color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for focus */
  transition: all 0.3s ease; /* Smooth hover effect */
}

.city-input::placeholder {
  color: #f0e4f7; /* Light placeholder text */
}

.city-input:focus {
  outline: none;
  border-color: #ff758c; /* Highlighted border */
  background-color: #fff; /* Contrast for active state */
  color: #333; /* Dark text on white background */
}

.suggestions-dialog {
  position: absolute;
  top: calc(100% + 5px); /* Adds some spacing below the input */
  left: 50%;
  transform: translateX(-50%);
  width: 60%; /* Matches input width */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px; /* Rounded bottom corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Subtle depth */
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.suggestions-dialog::-webkit-scrollbar {
  width: 8px;
}

.suggestions-dialog::-webkit-scrollbar-thumb {
  background: #ff758c;
  border-radius: 8px;
}

.suggestions-dialog::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.suggestion-item {
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  color: #333; /* Neutral text color */
  transition: background-color 0.2s ease, color 0.2s ease;
}

.suggestion-item:hover {
  background-color: #ff758c; /* Highlighted background */
  color: #fff; /* Contrast text */
}

.checkout-button {
  padding: 12px 25px;
  font-size: 16px;
  background-color: #ff758c;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for button */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.checkout-button:hover {
  background-color: #ac35b3; /* Darker shade on hover */
  transform: scale(1.05); /* Slight zoom */
}

@media (max-width: 768px) {
  .city-input {
    width: 90%;
  }

  .suggestions-dialog {
    width: 90%;
  }
}
