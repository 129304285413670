.admin-dashboard {
    display: flex;
  }
  
  .side-panel {
    width: 250px;
    background-color: #333;
    color: white;
    padding: 20px;
  }
  
  .side-panel ul {
    list-style: none;
    padding: 0;
  }
  
  .side-panel li {
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .side-panel li.active {
    font-weight: bold;
    color: #ff5722;
  }
  
  .main-page {
    flex-grow: 1;
    padding: 20px;
  }
  

  