/* SideMenu.css */
.side-menu {
  position: relative;
  left: 0;
  width: 200px;
  /* background-color: rgba(245, 245, 245, 0.9); */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 15px 15px 0;
  overflow-y: auto;
  transition: left 0.3s;
  text-align: center;
}

.service-list {
  list-style: none;
  padding: 0;
  margin: 0;
  /* color: white; */
}

.service-item {
  padding: 10px;
  background-color: #AC2FB1;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1px;
  color: white;
  font-weight:bold;
}
.service-link {
  text-decoration: none;
  color: inherit;
  display: block; 
}


@media (max-width: 768px) {
  .side-menu {
    top: 0;
    left: 0;
    width: 100%;
    /* background-color: rgba(245, 245, 245, 0.9); */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    border-radius: 0;
    margin-bottom: 10px;
  }
  .webkit-scrollbar-track {
    background: #000;
  }
  .service-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px;
    overflow-x: auto;
  }

  .service-item {
    padding: 10px;
    background-color: #fff;
    color: #AC2FB1;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    /* margin-right: 10px; */
    margin: auto;
  }

  .service-item:last-child {
    margin-right: 0;
  }

  .service-item:hover {
    /* background-color: #f0f0f0; */
    text-decoration: underline;
  }

  .menu-toggle {
    display: none;
  }
}
