
.header1 {
  position: relative;
  width: 100%;
  height: 70vh;
  background-image: url("D:/Fotoshoot/client/public/freelance.webp");
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;
}

.headerContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.headerContent h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.headerContent p {
  font-size: 18px;
  margin-bottom: 30px;
}

.headerContent button {
  padding: 12px 24px;
  background-color: #ac35b3;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.headerContent button:hover {
  background-color: #ba181b;
}
.toBeFreelancerPage1 {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-container1 {
  max-width: 500px;
  width: 100%;
}
.input-field,
.textarea-field {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.textarea-field {
  resize: vertical;
}
.submit-button {
  padding: 10px 20px;
  background-color: #ac35b3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.form-container1 button {
  display: block;
  margin: 0 auto;
}
.submit-button:hover {
  background-color: #ba181b;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-bottom: 50px;
}

.infoCard1 {
  max-width: 500px;
  padding: 20px;
  background: linear-gradient(135deg, #fce38a 0%, #f38181 100%); 
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  font-family: "Arial", sans-serif; 
  color: #333;
  font-family: "Montserrat", sans-serif;
}

.infoCardTitle {
  font-size: 40px;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
}

.bulletPoints {
  list-style-type: disc;

}

.bulletPoints li {
  font-size: 20px;
  color: #555;
  margin-bottom: 10px;
}
