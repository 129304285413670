/* ContactPage.css */
.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .social-media-links {
    margin-bottom: 20px;
  }
  
  .social-media-links a {
    margin: 0 10px;
    color: #ac35b3;
    font-size: 24px;
  }
  
  .contact-form {
    max-width: 400px;
    text-align: center;
    width: 100%;
    background-color: #709ba5;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }
  
  .email-button {
    display: inline-block;
    background-color: #ac35b3;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  .phone-number {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ac35b3;
    margin-bottom: 5px;
  }
  
  .phone-number svg {
    margin-right: 10px;
  }