.get-in-touch-content {
  display: flex;
  flex-direction: column;
  background-image: url('/public/images/Banner, Popup, Recommendation Images/Limited Time Offer.webp');
  background-size: cover;
  background-position: center;
  padding: 40px;
  border-radius: 15px;
  z-index: 999;
  align-items: center;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  margin-top: 30px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.get-in-touch-content-img {
  height: 100px;
  width: 150px;
  margin-bottom: 20px;
}

.get-in-touch-para-p1 {
  font-size: 28px;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
}

.get-in-touch-para-p2 {
  cursor: pointer;
  text-decoration: underline;
  color: #ffe066;
  font-size: 20px;
  font-weight: 600;
}

.shutter {
  position: fixed;
  top: 0;
  right: -300px;
  width: 510px;
  height: 100vh;
  background-color: #fff;
  border-radius: 15px 0 0 15px;
  transition: right 0.5s ease-in;
  z-index: 999999;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
}

.shutter.open {
  right: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #ff758c;
  font-weight: bold;
}

.form-container-touch {
  padding: 20px;
}

.form-container-touch h3 {
  margin-bottom: 10px;
  color: #333;
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-size: 24px;
}

.form-container-touch input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-container-touch button {
  width: 100%;
  padding: 12px;
  background-color: #ff758c;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.form-container-touch button:hover {
  background-color: #e05570;
  transform: translateY(-2px);
}

.checkout-button {
  background-color: #ff758c;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  border-radius: 8px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  gap: 5px;display: block;
  margin: 0 auto;
}

.checkout-button:hover {
  background-color: #e05570;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .get-in-touch-content {
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .get-in-touch-para-p1 {
    font-size: 22px;
  }

  .shutter {
    width: 100%;
    border-radius: 0;
  }
}
