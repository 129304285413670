.container1 {
  max-width: 100%;
}
.above-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ac35b3;
  padding: 10px;
}
.contact-info {
  flex: 1;
  color: #fff;
  font-weight: bold;
}


.social-media-linkss {
  display: flex;
  gap: 10px;
}
.social-media-linkss a {
  margin: 0 5px;
  color: #fff;
  font-size: 20px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  position: relative;
  color: #000000;
  /* border-radius:0 0 0 80% ; */
}
.navbar-logo {
  margin-left: 40px;
  font-size: 30px;
  border: 0;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;
  color: #ac35b3;
}

.burger-icon {
  display: none;
  cursor: pointer;
  padding: 15px;
  color: #E63946;
}
.line {
  width: 25px;
  height: 2px;
  background-color: #fff;
  margin: 5px;
  transition: transform 0.2s ease-in-out;
}
.active .line:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.active .line:nth-child(2) {
  opacity: 0;
}
.active .line:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}
.navbar-menu {
  list-style: none;
  display: flex;
  align-items: center;
}
.navbar-item {
  margin-right: 20px;
  font-family: "Montserrat", sans-serif;
}
.navbar-link {
  text-decoration: none;
  color: #fff;
  position: relative;
}
.navbar-link::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #F9A826;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.2s ease-in-out;
}
.navbar-link:hover::before {
  transform: scaleX(1);
  background-color: #ac35b3;
}
.navbar-buttons {
  display: flex;
  align-items: center;
  margin-right: 100px;
}
.navbar-profile{
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.navbar-button {
  padding: 10px 15px;
  margin-left: 20px;
  border: 2px solid #ac35b3;
  border-radius: 4px;
  text-decoration: none;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.navbar-button1 {
  margin-left: 20px;
  margin-bottom: 20px;
  border: 2px solid #ac35b3;
  border-radius: 4px;
  text-decoration: none;
  background: #000;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.navbar-button:hover {
  background-color: #ac35b3; 
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .contact-info p {
    white-space: break-spaces;
  }
  .above-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 768px) {
  .navbar-menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #000;
    margin: 0;
    padding: 0;
    z-index: 2;
  }
  .navbar-logo {
    margin-left: 2px;
    font-size: 10px;
    font-weight: bold;
    text-decoration: none;
    background-color: #fff;
    color: #ac35b3;
  }
  .navbar-item {
    margin: 0;
    padding: 15px;
    text-align: center;
    width: 100%;
    font-family: "Montserrat", sans-serif;
  }
  .navbar-link {
    text-decoration: none;
    color: #fff;
    position: relative;
  }
  .navbar-menu.active {
    display: flex;
  }
  .burger-icon {
    display: block;
  }
  .navbar-menu:not(.active) {
    display: none;
  }
  .navbar-buttons {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  
  .navbar-button {
    padding: 10px 15px;
    margin-left: 20px;
    border: 2px solid #ac35b3;
    border-radius: 4px;
    text-decoration: none;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }
}
/* Dropdown button */
.dropbtn {
  background-color: transparent;
  color: #333;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  margin-right: 150px;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: black;
  border-radius: 10px;
  border: 2px solid #ac35b3;
  min-width: 145px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99999999;
}

/* Links inside the dropdown */
.navbar-Userbtn {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.navbar-Userbtn:hover {
  background-color: #ac35b3;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
