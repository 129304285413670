.container13 {
  /* position: absolute; 
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 100%; /* adjust as needed */
  height: 100%; /* adjust as needed */
  /* background-color: #fff; */
  /* box-shadow: 0 30px 50px #a9a8a8; */
  overflow: hidden;
  position: relative;
  background-color:#f9f9f9;
}

#slide1 {
  width: 100%;
  height: 100%;
}
 /* .item {
  width: 200px;
  height: 300px;
  background-size: cover;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  box-shadow: 0 30px 50px #505050;
} */
.item {
  width: 200px;
  height: 300px;
  background-position: 50% 50%;
  display: inline-block;
  transition: 0.8s;
  background-size: cover;
  position: absolute;
  z-index: 1;
  /* top: 50%;
  transform: translate(0, -50%); */
  border-radius: 20px;
  box-shadow: 0 30px 50px #505050;
}
.item:nth-child(2) {
  transform: translate(0, 0);
  border-radius: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
}
.item:nth-child(3) {
  top: 30%;
  left: 60%;
}
.item:nth-child(4) {
  top: 30%;
  left: calc(60% + 220px);
  
}
.item:nth-child(5) {
  top: 30%;
  left: calc(60% + 440px);
}
.item:nth-child(n + 6) {
  top: 30%;
  left: calc(60% + 660px);
  opacity: 0;
}
.item .content {
  position: absolute;
  top: 40%;
  left: 180px;
  width: 300px;
  text-align: left;
  padding: 0;
  color: #eee;
  transform: translate(0, -50%);
  display: none;
}
.item:nth-child(2) .content {
  display: block;
  z-index: 11111;
}
.item .name {
  font-size: 70px;
  font-weight: bold;
  opacity: 0;
  animation: showcontent 1s ease-in-out 1 forwards;
  color: white;
  text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.8);
}
.item .des {
  font-size: 20px;
  margin: 20px 0;
  opacity: 0;
  animation: showcontent 1s ease-in-out 0.3s 1 forwards;
  animation: showcontent 1s ease-in-out 1 forwards;
  color: white;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
}
.item button {
  padding: 10px 20px;
  border: none;
  opacity: 0;
  animation: showcontent 1s ease-in-out 0.6s 1 forwards;
  background-color: #bac383;
}
@keyframes showcontent {
  from {
    opacity: 0;
    transform: translate(0, 100px);
    filter: blur(33px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
    filter: blur(0);
  }
}
.buttons {
  position: absolute;
  bottom: 70px;
  z-index: 222222;
  text-align: center;
  width: 100%;
  
}
.buttons button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #555;
  transition: 0.5s;
  background-color: #bac383;
}
.buttons button:hover {
  background-color: #bac383;
}
@media screen and (max-width: 768px) {
  .container13 {
    /* position: absolute; 
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%); */
    width: 100%; /* adjust as needed */
    height: 100%; /* adjust as needed */
    background-color: #fff;
    /* box-shadow: 0 30px 50px #a9a8a8; */
    overflow: hidden;
  }
    #slide1 {
      width: 100%;
      height:100%;
    }
    .item {
        width: 163px;
        border-radius: 20px 0px 0px 20px;
        display: none; 
    }
  
    .item:nth-child(2) {
      display: block; 
    }
    .item:nth-child(3) {
        
    }
    .item .content {
        position: absolute;
        top: 40%;
        left: 3%;
        width: 275px;
        text-align: left;
        padding: 0;
        color: #eee;
        transform: translate(0, -50%);
        display: none;
      }
      .item:nth-child(2) .content {
        display: block;
        z-index: 11111;
      }
      .item .name {
        font-size: 40px;
        font-weight: bold;
        opacity: 0;
        animation: showcontent 1s ease-in-out 1 forwards;
        color: white;
        /* text-shadow: 2px 4px 6px rgba(255, 255, 255, 0.8); */
      }
      .item .des {
        font-size: 18px;
        margin: 20px 0;
        opacity: 0;
        animation: showcontent 1s ease-in-out 0.3s 1 forwards;
        color: white;
        /* text-shadow: 4px 4px 6px rgba(255, 255, 255, 0.8); */
      }
      .item button {
        padding: 10px 20px;
        border: none;
        opacity: 0;
        animation: showcontent 1s ease-in-out 0.6s 1 forwards;
      }
  }