.selected-service-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 25px;
  }
  
  .city {
    margin-bottom: 10px;
  }
  
  .city a {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .city a:hover {
    background-color: #e0e0e0;
  }
  
  @media (max-width: 768px) {
    .selected-service-list {
      flex-direction: column;
    }
  }
  