/* FAQ Section Styling */
.faq-wrapper {
  margin: 40px auto;
  padding: 30px;
  background: white; /* Warm gradient */
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Enhanced depth */
  border-left: 5px solid #ff758c;
  max-width: 100%;
  color: #333;
}

.faq-wrapper h2 {
  font-size: 28px;
  text-align: center;
  margin-bottom: 15px;
  color: #ff758c;
  font-family: 'Merriweather', serif; /* Stylish font */
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25); /* Subtle text shadow */
}

.faq-heading {
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-family: 'Merriweather', serif;
}

.faq-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.faq-item {
  font-size: 18px;
  line-height: 1.8; /* Improved readability */
  background-color: #ffffff; /* Cleaner and brighter background */
  margin-bottom: 20px; /* Add spacing between items */
  padding: 20px 25px; /* More comfortable padding */
  text-align: justify;
  border-left: 5px solid #ff758c; /* Aesthetic border accent */
  border-radius: 8px; /* Smooth corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  color: #333; /* Soft dark color for text */
  font-family: 'Merriweather', serif; /* Elegant serif font */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth hover effect */
}

.faq-item:hover {
  transform: translateY(-5px); /* Lifts the item slightly */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.faq-question {
  font-weight: bold;
  font-size: 18px;
  color: #333; /* Distinctive color */
}

.faq-answer {
  font-size: 16px;
  color: #555;
  margin-top: 10px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .faq-wrapper {
    padding: 20px;
  }

  .faq-item {
    padding: 15px;
  }

  .faq-question {
    font-size: 16px;
  }

  .faq-answer {
    font-size: 14px;
  }
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.whatsapp-button {
  position: fixed;
  left: 100px;
  bottom: 20px; 
  transform: translateX(-50%);
  border: none;
  border-radius: 50%;
  background-color: transparent;
  width: 70px;
  height: 70px;
  font-size: 24px;
  cursor: pointer;
  z-index: 999; 
}


.service-name {
  font-size: 45px;
}
.service-info {
  max-width: 95%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 5px;
}

.service-info-paragraph {
  font-size: 18px;
  line-height: 1.8; /* Improved readability */
  background-color: #ffffff; /* Cleaner and brighter background */
  margin-bottom: 20px; /* Add spacing between paragraphs */
  padding: 20px 25px; /* More comfortable padding */
  text-align: justify;
  border-left: 5px solid #ff758c; /* Aesthetic border accent */
  border-radius: 8px; /* Smooth corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  color: #333; /* Soft dark color for text */
  font-family: 'Merriweather', serif; /* Elegant serif font */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth hover effect */
}
.service-info-paragraph>p {
  font-size: 18px;
  line-height: 1.8; /* Improved readability */
  background-color: #ffffff; /* Cleaner and brighter background */
  margin-bottom: 20px; /* Add spacing between paragraphs */
  padding: 20px 25px; /* More comfortable padding */
  text-align: justify;
  border-left: 5px solid #ff758c; /* Aesthetic border accent */
  border-radius: 8px; /* Smooth corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  color: #333; /* Soft dark color for text */
  font-family: 'Merriweather', serif; /* Elegant serif font */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth hover effect */
}

/* Add hover effect */
.service-info-paragraph:hover {
  transform: scale(1.02); /* Slight zoom */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
}

.service-info-paragraph > h2 {
  font-size: 28px; /* Larger font for emphasis */
  line-height: 1.5; /* Comfortable spacing */
  color: #ff758c; /* Accent color matching the border */
  margin-bottom: 15px; /* Space below the heading */
  font-family: 'Playfair Display', serif; /* Elegant serif font */
  font-weight: 600; /* Slightly bold */
  text-transform: capitalize; /* Consistent capitalization */
  border-bottom: 2px solid #ff758c; /* Underline accent */
  padding-bottom: 5px; /* Spacing for the underline */
}

.service-info-paragraph > h3 {
  font-size: 22px; /* Slightly smaller than h2 */
  line-height: 1.4; /* Maintain readability */
  color: #333; /* Neutral color for subheadings */
  margin-bottom: 10px; /* Space below the heading */
  font-family: 'Merriweather', serif; /* Matches paragraph font for consistency */
  font-weight: 500; /* Medium weight */
  text-transform: capitalize; /* Consistent capitalization */
  border-left: 4px solid #ff758c; /* Left border for emphasis */
  padding-left: 10px; /* Space between text and border */
}

.image-container {
  max-width: 100%;
  height: 650px;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px; /* Add some space for scrollbar */
  margin-bottom: 70px; /* Add some space at the bottom */
}

.image-container::-webkit-scrollbar {
  overflow: hidden;
}


.image-container img {
  max-width: 100%; /* Ensure images fit within the container */
  height: auto;
  display: inline-block;
  margin: 0 4px; /* Adjust spacing between images */
  vertical-align: top;
}

.additional-info {
  position: relative;
  border: 2px solid #ddd;
  max-width: 100%;
  height: 300px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content {
  position: relative;
  padding: 20px;
  z-index: 1;
}

.content>h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

.content>p {
  font-size: 18px;
  color: #000;
  margin-bottom: 100px;
}

/* .city-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.city-input {
  width: 200px;
  padding: 10px;
  margin-right: 2px;
  border: 1px solid #ccc;
  font-size: 16px;
} */

.checkout-button {
  padding: 8px 16px;
    background-color: #aebe45;
    color: white;
    border: none;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 2px 2px black;
}

.checkout-button:hover {
  background-color: #8e9d6e;
}



.video-button {
    display: inline-block;
    width: 40px; 
    height: 40px;
    background-color: #a1b; 
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    transition: background-color 0.3s ease;
    overflow: hidden;
    margin-left: 2px;
  }
  
  .video-button:hover {
    background-color: #8a9; 
  }
  .section-title {
    font-size: 2em;
  }
  
  .section-subtitle {
    font-size: 1.5em;
  }
  
  .faq-list {
    list-style: none;
    padding: 0;
  }
  
  .question {
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1.6em;
  }
  
  .answer {
    font-size: 1.2em;
    line-height: 1.6em;
  }
  
  .section-text {
    font-size: 1em;
    line-height: 1.6em;
  }
  
  
  @media (max-width: 768px) {
    .video-button {
        display: inline-block;
        width: 30px; 
        height: 30px;
        background-color: #a1b; 
        color: #fff;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        font-size: 15px;
        position: relative;
        transition: background-color 0.3s ease;
        overflow: hidden;
        margin-left: 2px;
      }
      
      .video-button:hover {
        background-color: #8a9;
      }
      .service-info-paragraph>h2 {
        text-align: left;
      }
      .service-info-paragraph>h3 {
        text-align: left;
      }
  }
  .latest-prewedding-shoots {
    text-align: center;
    margin-top: 5px;
  }
  
  .shoots-title {
    font-size: 36px;
    margin-bottom: 30px;
    color: #333;
  }
  
  .image-containerq {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .shoot-image {
    width: 600px;
    height: 400px;
    margin: 10px; /* Adjust the margin to create spacing between images */
    transition: transform 0.3s ease-in-out; /* Adding a subtle transform animation */
  }
  
  .shoot-image:hover {
    transform: scale(1.1); /* Enlarge the image slightly on hover */
  }
  @media(max-width:767px){
    .image-container {
      max-width: 100%;
      height: 400px;
      overflow-x: auto;
      white-space: nowrap;
      padding-bottom: 20px; /* Add some space for scrollbar */
      margin-bottom: 25px;
    }
    .shoot-image {
      width: 600px;
      height: 300px;
      margin: 10px; /* Adjust the margin to create spacing between images */
      transition: transform 0.3s ease-in-out; /* Adding a subtle transform animation */
    }  
    .whatsapp-button {
      position: fixed;
      left: 40px;
      bottom: 50px; 
      transform: translateX(-50%);
      border: none;
      border-radius: 50%;
      background-color: transparent;
      width: 40px;
      height: 40px;
      font-size: 24px;
      cursor: pointer;
      z-index: 999; 
    }
    
  }
  @media(max-width:767px){
    .service-info {
      max-width: 95%;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 40px;
    }
    
    .service-info-paragraph {
      font-size: 14px;
      line-height: 1.6;
      margin-bottom: 5px;
      text-align: justify;
    }
  }

  .cards-containerxd {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  .cardxd {
    flex: 1 1 300px;
    background: antiquewhite;
    transition: all 0.4s;
    border-radius: 10px;
  }
  
  .cardxd:hover {
    border-radius: 10px;
    cursor: pointer;
    transform: scale(1);
    background: #e2e2e2;
    z-index: 22;
  }
  
  .first-content {
    height: 100%;
    width: 100%;
    transition: all 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border-radius: 15px;
    text-align: center;
    color: #ac35b3;
  }
  
  .cardxd:hover .first-content {
    height: 0px;
    opacity: 0;
  }
  
  .second-content {
    height: 0%;
    width: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding: 15px;
    transition: all 0.4s;
    transform: rotate(90deg) scale(-1);
    text-align: justify;
  }
  
  .cardxd:hover .second-content {
    opacity: 1;
    height: 100%;
    font-size: 1.8rem;
    transform: rotate(0deg);
  }
  @media (max-width: 768px) {
    .cardxd {
      flex-basis: 100%;
      height: 200px;
    }
    .cardxd:hover{
      width: 80%;

    }
  } 