.form-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 100px;
    /* padding: 20px; */
    max-width: 80%; 
    margin: 0 auto;
}

.form-box {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    width: 250px;
    margin-right: 10px;
}

.form-box input[type="file"] {
    margin-bottom: 10px;
}

.form-box button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
}
@media (max-width:676px) {
    .form-box{
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 150Spx;
    }
}