
  .frontpage{
    max-width: 100vw;
    height: 100%;
  }
  .content-container {
    width: 100%; 
    height: 100vh;
  }
  
  .portfolio-image {
    max-width: 100%; 
    margin: 0 auto;
    padding: 20px;
    position: relative;
    z-index: 1;
  }
  .content-container1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    background-color: #eef5ff;
  }
  
  .lottie-animation {
    flex: 1;
    max-width: 50%;
  }
  
  .lottie-animation h1 {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 2rem;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .content-container1-p {
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
  }
  
  
  .content-container1-p p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: center;
    align-self: center;
  }
  
  .content-container1-p button {
    padding: 10px 20px;
    border: none;
    background-color: #bac383;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: center;
  }
  
  .content-container1-p button:hover {
    background-color: #8e9d6e;
  }
  .threed{
    width: 100%;
    height: 600px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: #f9f9f9;
  }
  /*first block*/
  .custom-container {
    display: flex;
    align-items: center;
    /* margin-top: 50px;  */
    background-color: #f9f9f9;
    height: 400px;
  }
  
  .custom-half {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    position: relative;
  }
  
  .custom-heading {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .custom-subheading {
    text-align: center;
    color: #666;
    margin-bottom: 20px;
  }
  
  .custom-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .custom-select {
    padding: 10px;
    width: 45%;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .custom-gif {
    width: 100%; 
  }
  
  .custom-select.city-input {
    border-radius: 4px 4px 0 0; /* Rounded top corners for city input */
  }
  
  .suggestions-dialogd {
    position: absolute;
    top: calc(100% - 10px); /* Adjust position to be just below the input */
    left: 0;
    width: 100%;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-top: none; /* Removes the top border */
    border-radius: 0 0 4px 4px; /* Rounded bottom corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
    padding: 0; /* Removes padding to match the input */
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .custom-btn {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #bac383;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px black;
    cursor: pointer;
  }
  
  .custom-btn:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .custom-select {
      width: 100%;
    }
  
    .suggestions-dialogd {
      width: 100%;
    }
  }
  
  /* .custom-half {
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  
  .custom-heading,
.custom-subheading {
  text-align: center;
  font-size: 36px; 
  margin-bottom: 25px; ]
  font-family: "Montserrat", sans-serif;
}

.custom-subheading {
  font-size: 20px; 
  color: #555; 
  margin-bottom: 20px; 
}

  
  .custom-form {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .custom-input {
    padding: 8px;
    margin-right: 2px;
    border: 1px solid #ccc;
  }
  .custom-select {
    padding: 8px;
    margin: 8px 8px;
    border: 1px solid #ccc;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="6"><polygon points="6 0 0 6 12 6"/></svg>') no-repeat;
    background-position: calc(100% - 12px) center;
    background-size: 10px;
    padding-right: 30px;
  }
  
  .custom-btn {
    padding: 8px 16px;
    background-color: #bac383;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 2px 2px black;
  } */
  /* For smaller screens */
@media screen and (max-width: 768px) {
  .frontpage{
    height: 100%;
    width: 100vw;
  }
  .custom-container {
    display: block; /* Change to block display for smaller screens */
    height: auto; /* Reset height */
  }
  .custom-heading {
    font-size: 28px; /* Adjust font size as needed */
    margin-bottom: 18px; /* Adjust margin as needed */
  }
  
  .custom-subheading {
    font-size: 14px; /* Adjust font size as needed */
    color: #555; /* Adjust color as needed */
    margin-bottom: 20px; /* Adjust margin as needed */
  }
  .custom-gif{
    display: none;
  }
  .custom-half {
    width: 100%; /* Full width for smaller screens */
    padding-top: 25px;
  }
  .custom-btn{
    margin-bottom: 20px;
  }
  
  .custom-form {
    flex-direction: column; /* Change to column for smaller screens */
  }
  
  .custom-input {
    width: 90%; /* Make inputs full width */
    margin: 5px 5px; /* Adjust margin */
  }
  .custom-select {
    padding: 8px;
    margin: 8px 0;
    width: 90%;
    border: 1px solid #ccc;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="6"><polygon points="6 0 0 6 12 6"/></svg>') no-repeat;
    background-position: calc(100% - 12px) center;
    background-size: 10px;
    padding-right: 30px;
  }
  .threed{
    width: 100%;
    height: 480px;
    padding: 0;
    background-color: #f9f9f9;
  }
  .content-container1-p p {
    font-size: 0.7rem;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: center;
    align-self: center;
  }
}

  /*second block*/
  

  
  @media screen and (max-width: 768px) {
      .portfolio-image {
        max-width: 100%; 
        margin: 0 auto;
        margin-top: 10px;
        padding: 20px;
        position: relative;
        z-index: 1;
      }
  }
  
/* Portfolio.css - Add or modify these styles */

.about-us-container1 {
  background-color: #f9f9f9;
  padding: 50px 20px;
  min-height: 400px;
  text-align: center;
}

.about-us-content {
  display: flex;
  flex-direction: row; /* Default: Image and text side by side */
  align-items: center;
  justify-content: space-between;
  font-family: 'Montserrat', Arial, sans-serif;
}

.about-us-text {
  width: 50%; /* Initial width for larger screens */
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Montserrat', Arial, sans-serif;
}

.about-us-text h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: 'Montserrat', Arial, sans-serif;
}

.about-us-image img {
  max-width: 100%;
  height: auto; /* maintains aspect ratio */
  max-height: 400px; /* limits the image height */
  object-fit: cover; /* ensures the image fits nicely */
}


/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .about-us-content {
    flex-direction: column; /* Image comes after text on smaller screens */
  }

  .about-us-text {
    width: 100%; /* Full width for text on smaller screens */
    font-family: 'Montserrat', Arial, sans-serif;
  }

  .about-us-image {
    margin-top: 20px; /* Add margin to separate text and image on smaller screens */
  }
}



/* Portfolio.css - Add styles for the testimonial section */
.testimonial-container {
  text-align: center;
  padding: 50px 20px;
  background-color: #EEF5FF;
}

.testimonial-header {
  margin-bottom: 20px;
}

.client-photo {
  width: 150px; /* Adjust size as needed */
  height: 150px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the photo circular */
  overflow: hidden; /* Ensures circular shape */
}

.section-heading {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.testimonial-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonial-item {
  width: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.testimonial-item:hover {
  transform: scale(1.05);
}

.testimonial-text {
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial-author {
  font-weight: bold;
}

.write-review-link {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #bac383;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  box-shadow: 2px 2px black;
  overflow: hidden; /* Hide overflow content to create the collapse effect */
  transition: max-height 0.5s ease-out; /* Added transition for the collapsing animation */
  max-height: 50px; /* Set initial max-height for the collapsed state */
}

.write-review-link:hover {
  background-color: #8e9d6e;
}

.centered-button {
  display: flex;
  justify-content: center;
}

.write-review-link.expanded {
  max-height: 200px;
}
/* For smaller screens */
@media screen and (max-width: 768px) {
  .testimonial-item {
    width: calc(100% - 40px);
    margin: 20px 0;
  }

  .testimonial-list {
    justify-content: center;
  }
}



/* .container1307 {
  width: 100%;
  height: 100%;
  background-image: conic-gradient(#bac383, #8e9d6e);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: orange;
} */
.custom-content1{
  width: 100%;
  height: 300px;
  background-color: #EEF5FF;
  margin: 0;
}
.custom-subcontent1{
  font-size: 20px; /* Adjust font size as needed */
  color: #555; /* Adjust color as needed */
  text-align: center;
  padding: 50px;
  margin-bottom: 0px;
}
@media screen and (max-width: 768px) {
  .custom-content1{
    width: 100%;
    height: auto;
    background-color: #EEF5FF;
    margin: 0;
  }
  .custom-subcontent1{
    font-size: 12px; /* Adjust font size as needed */
    color: #555; /* Adjust color as needed */
    text-align: center;
    padding: 30px;
  }
}
.faq-container {
  width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 20px;
  text-align: justify;
}

.faq-heading {
  text-align: start;
  font-size: 2rem;
  margin-bottom: 30px;
}

.faq-item {
  background-color: #EEF5FF;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
}

.faq-question {
  font-weight: bold;
}

.faq-question span {
  font-weight: normal;
}

