*{
  font-family: "Montserrat", sans-serif;
}

.subtitle{
    text-align: center;
    font-size: x-large;
    font-family: "Montserrat", sans-serif;
}

.PricingCheckBox {
  margin: auto;
    max-width: 50%;
    margin-bottom: 50px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .labell {
    display: block;
    margin-bottom: 10px;
    font-size: 18px; 
  }
  
  .checkbox-input {
    margin-right: 8px;
  }
  .custom-price1 {
    display:block;
    gap: 10px;
    margin-left: 25px;
    color: #ac35b3;
    font-weight: bold;
    font-size: 16px;
  }
  .but1 {
    width: 100%;
    padding: 10px;
    background-color: #ac35b3;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  .custom-button{
    color: white;
    background-color: #ac35b3;
    border: 0;
    border-radius: 5px;
    margin-right: 5px;
  }
  
  @media (max-width:767px) {
    .PricingCheckBox {
        max-width: 100%;
        margin-left: 20px;
        margin-bottom: 50px;
        margin-right: 20px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
  }



  .bundle-form-container {
    margin: auto;
    max-width: 50%;
    margin-bottom: 50px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .bundle-offer-heading {
    font-size: x-large;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .bundle-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .bundle-item {
    margin-bottom: 10px;
  }
  
  .bundle-label {
    display: block;
  }
  
  .bundle-checkbox-input {
    margin-right: 10px;
  }
  
  .bundle-bill {
    margin-top: 30px;
    border: 1px solid #ccc;
    padding: 10px;
  }
  @media (max-width:767px) {
    .bundle-form-container {
      margin: auto;
      max-width: 90%;
      margin-bottom: 50px;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }