.main-invoice-div1{
    width: 100vw;
    min-height: 100vh;
}
.payment-button{
    display: flex;
    color: white;
    background-color: #ac35b3;
    border-radius: 5px;
    border:0;
    justify-content:center ;
    align-items: center;
    padding: 5px;
    margin: auto;
}
.payment-table1 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 16px;
  }
  
  .payment-table1 th,
  .payment-table1 td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  


  @media (max-width: 767px) {
    .main-invoice-div1{
        width: 100vw;
        height:100%;
    }
    .payment-table1 {
      font-size: 14px;
    }
  
    .payment-table1 th,
    .payment-table1 td {
      padding: 8px;
      display: block;
      text-align: left;
    }
  
    .payment-table1 thead {
      display: none;
    }
  
    .payment-table1 tbody tr {
      margin-bottom: 15px;
      display: block;
      border: 1px solid #ddd;
    }
  
    .payment-table1 tbody td::before {
      content: attr(data-label);
      font-weight: bold;
      display: inline-block;
      width: 100px;
    }
  
    .payment-button {
      display: block;
      width: 100%;
      margin-top: 10px;
    }
  }
  
  .submit1{
    bottom: 10;
    background-color: #ac35b3;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    }
    .submit1:hover{
      background-color: #ac35b3;
    }