.footer {
  background-color: #001C30;
  color: #fff;
  padding: 20px;
  position: relative;
}

.footerColumns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footerColumnsHome {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footerColumn {
  flex: 1;
  max-width: 200px;
  margin: 10px;
  font-size: 15px;
  position: relative; /* For positioning the line */
}

.footerColumn h3 {
  font-size: 25px;
  margin-bottom: 10px;
  color: #fff;
}

.footerColumn ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footerColumn ul li {
  margin-bottom: 5px;
}

.footerColumn ul li a {
  color: #fff;
  text-decoration: none;
}

.footerColumn ul li a:hover {
  text-decoration: underline;
}

.footerLogo {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.footerLine {
  border: none;
  border-top: 1px solid #fff;
  width: 80%;
  margin: 20px auto 0;
}

.footerText {
  font-size: 18px;
  text-align: center;
}

.socialMediaIcons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.socialMediaIcons a {
  display: inline-block;
  color: #fff;
  font-size: 20px;
  margin: 0 5px;
}

/* Numbers don't lie */
.counter {
  display: inline-block;
}

.counter-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.counter-value {
  font-size: 20px;
  font-weight: bold;
}

.counter-label {
  font-size: 18px;
}

/* Services section */
.footerColumnsHome.services-sectionHome {
  display: flex;
  flex-direction: column; /* Stack sections vertically */
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}

.serviceDivHome {
  width: 100%; /* Full width for each service section */
  margin: 20px 0; /* Space between sections */
  background-color: transparent;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.serviceTitleHome {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #fff;
}

.cityListHome {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap; /* Wrap cities to next line */
  justify-content: center; /* Center align cities */
}

.cityListHome li {
  width: 24%; /* Adjust width for four cities per row */
  margin: 5px; /* Space around each city */
  text-align: left;
  font-size: 0.9em;
}

.cityListHome li a {
  color: #fff;
  text-decoration: none;
}

.cityListHome li a:hover {
  text-decoration: underline;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .cityListHome li {
    width: 47%; /* Adjust width for four cities per row */
    margin: 5px; /* Space around each city */
    text-align: left;
    font-size: 0.7em;
  }
}
