.banner-container {
  display: flex;
  height: 250px;
  margin-bottom: 25px;
  flex-direction: row;
}

.banner-left {
  width: 55%;
  position: relative;
  overflow: hidden;
}

.banner-right {
  width: 45%;
  margin: auto;
}

.banner-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: white; /* Or any background color of your choice */
  clip-path: circle(45% at left center); /* Creates the inward semicircle effect on the left side */
}

.banner-left.mobile::before {
  clip-path: circle(70% at bottom center); /* Creates the inward semicircle effect on the bottom side for mobile */
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the image covers the container */
}

.banner-catchy-line {
  padding: 20px;
  background-color: #fff;
  text-align: center;
  font-size: 56px;
}

.banner-right-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
}

.banner-city-input-container {
  margin: 20px;
  width: 100%;
}

.banner-city-input,
.banner-checkout-button {
  width: auto;
  padding: 10px;
  margin-right: 1px;
  margin-bottom: 10px;
}

.banner-checkout-button {
  background-color: #bac383;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 2px 2px black;
}

.banner-checkout-button:hover {
  background-color: #8e9d6e;
}

@media (max-width: 768px) {
  .banner-container {
    display: flex;
    height: auto;
    margin-bottom: 25px;
    flex-direction: column-reverse;
  }

  .banner-left {
    width: 100%;
    height: 400px; /* Adjust height for mobile */
  }

  .banner-right {
    width: 100%;
    padding: 10px;
  }

  .banner-city-input-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 20px;
  }

  .banner-checkout-button {
    background-color: #bac383;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .banner-city-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .banner-right-form {
    flex-direction: row;
    margin: 0;
  }

  .banner-city-input,
  .banner-checkout-button {
    width: 180px;
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 12px;
  }

  .banner-catchy-line {
    background-color: #fff;
    text-align: center;
    font-size: 36px;
  }

  .banner-left::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 193%;
    background-color: white; /* Or any background color of your choice */
    clip-path: circle(65% at bottom center); /* Creates the inward semicircle effect on the bottom side */
  }
}
