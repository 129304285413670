.recommendations-container {
  margin-top: 30px;
  margin-bottom: 30px;
    font-family: Arial, sans-serif;
    width: 100%;
    /* margin: auto; */
    /* background-color: #eef5ff; */
    background-image: url('/public/images/Banner, Popup, Recommendation Images/Get Recommendations of Best Photographers in Your City.webp');
    opacity: 5; 
    background-size: cover; /* Ensures the image covers the whole background */
    background-position: center; /* Centers the image */
    padding: 5%;
  }
  
  .recommendations-container p {
    margin-bottom: 25px;
    color: white;
  }
  .recommendations-container h2 {
    margin-bottom: 25px;
    color: white;
  }
  
  .recommendations-container input {
    width: 400px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #eef5ff;
  }
  
  .rec-button {
    padding: 8px 16px;
    background-color: #ff758c;
    width: 20%;
    color: white;
    border: none;
    margin-top: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
    box-shadow: 2px 2px black;
  }
  .formatting{
    display: flex;
    flex-direction: column;
  }

  .formatting-input{
    border: 1px solid #000;
  }

  .rec-button:hover {
    background-color: #ac35b3;
  }
  
  .error-message {
    color: red;
    text-align: left;
  }
  .selectbut{
    width: 400px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #eef5ff;
  }
  @media(max-width:676px){
    .recommendations-container input {
      width: 90%;
      padding: 8px;
      margin-bottom: 10px;
      border: 1px solid #000;
      border-radius: 5px;
      background-color: #eef5ff;
    }
    .recommendations-container button {
        width: 40%;
        padding: 10px;
        background-color: #bac383;
        color: #fff;
        border: none;
        /* border-radius: 20px; */
        cursor: pointer;
        margin-bottom: 20px;
        box-shadow: 2px 2px black;
      }
      .selectbut{
        width: 90%;
        padding: 8px;
        margin-bottom: 10px;
        border: 1px solid #000;
        border-radius: 5px;
        background-color: #eef5ff;
      }
  }
  